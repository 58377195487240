module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Abhyudaya Sharma's Website and Blog","name":"Abhyudaya Sharma's Website and Blog","icons":[{"src":"favicon.ico","sizes":"64x64 32x32 24x24 16x16","type":"image/x-icon"},{"src":"logo192.png","type":"image/png","sizes":"192x192"},{"src":"logo512.png","type":"image/png","sizes":"512x512"}],"start_url":"/","display":"standalone","theme_color":"#000000","background_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/abhyudayasharma.github.io/abhyudayasharma.github.io/node_modules/gatsby-remark-images","id":"bce09f06-e8e7-51f3-b906-21b18621eacf","name":"gatsby-remark-images","version":"6.23.0","modulePath":"/home/runner/work/abhyudayasharma.github.io/abhyudayasharma.github.io/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"quality":70,"maxWidth":1024,"linkImagesToOriginal":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/runner/work/abhyudayasharma.github.io/abhyudayasharma.github.io/node_modules/gatsby-remark-prismjs","id":"a221fde1-b939-53df-b319-f95a48f90ae9","name":"gatsby-remark-prismjs","version":"6.23.0","modulePath":"/home/runner/work/abhyudayasharma.github.io/abhyudayasharma.github.io/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"languagePrefix":"language-"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"remarkPlugins":[],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/abhyudayasharma.github.io/abhyudayasharma.github.io","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":70,"maxWidth":1024,"linkImagesToOriginal":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
